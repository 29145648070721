<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]" class="d-flex align-items-center ">
				<a-col :span="8">
					<a-range-picker
						style="width: 100%;"
						format="YYYY-MM-DD"
						v-model="initialValue"
						:allowClear="false"
						@change="onChange"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						allowClear
						multiple
						@change="handleCompanyChange"
						:placeholder="l('Company')"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						v-model="departmentId"
						@change="search"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="4">
					<a-select
						mode="tags"
						v-model="empType"
						@change="search"
						style="width: 100%"
						placeholder="员工类型"
						allowClear
					>
						<a-select-option v-for="item in empTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4">
					<a-select
						mode="tags"
						show-search
						showArrow
						allowClear
						aria-required="true"
						option-filter-prop="children"
						v-model="attGroupId"
						style="width: 100%"
						@change="search"
						placeholder="考勤组"
					>
						<a-select-option v-for="item in attendanceGroup" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row :gutter="[10, 10]">
				<a-col span="20">
					<a-button type="primary" @click="analysis" v-if="isGranted('week_summary_analysis')">
						分析
					</a-button>
					<a-button type="primary" @click="exporter" v-if="isGranted('week_summary_excel')">
						<a-icon type="download" />
						<span>导出</span>
					</a-button>
				</a-col>
				<a-col :span="4" style="text-align: right">
					<a-input-search
						v-model.trim="filterText"
						placeholder="请输入搜索内容..."
						style="width: 100%;max-width: 200px"
						enter-button
						@search="getData"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<span slot="dutyFrom" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="auditTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="selfCheckTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="passTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpattdailyServiceProxy,
	EmpattmonthlyServiceProxy,
	EnterpriseAttendCycleServiceProxy,
} from '../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../shared/utils';
import * as _ from 'lodash';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import moment from 'moment';
import WeekAnalysis from './week-analysis/week-analysis';
import modalHelper from '../../../shared/helpers/modal/modal-helper';

export default {
	name: 'week-summary',
	mixins: [AppComponentBase],
	components: { WeekAnalysis },
	data() {
		return {
			attendanceGroup: [], //考勤组
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			abnormalTypeList: [], //异常类型
			startTime: undefined, //开始时间
			endTime: undefined, //结束时间
			attGroupId: undefined, //考勤组
			DailySummaryState: '', //状态(1:待审核、2"已审核、3:员工已确认、4:已结账)--数据字典
			columns: [
				{
					title: '工号',
					width: 100,
					sorter: false,
					ellipsis: true,
					dataIndex: 'jobNumber',
					fixed: 'left',
					align: 'center',
				},
				{
					title: '姓名',
					width: 100,
					sorter: false,
					ellipsis: true,
					dataIndex: 'empName',
					fixed: 'left',
					align: 'center',
				},
				{ title: '公司', width: 100, sorter: false, ellipsis: true, dataIndex: 'company', align: 'center' },
				{
					title: '一级部门',
					width: 100,
					sorter: false,
					ellipsis: true,
					dataIndex: 'department',
					align: 'center',
				},
				{
					title: '二级部门',
					dataIndex: 'twoDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{
					title: '三级部门',
					dataIndex: 'threeDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{ title: '入职日期', ellipsis: true, sorter: false, dataIndex: 'hireDate', align: 'center' },
				{ title: '离职日期', ellipsis: true, sorter: false, dataIndex: 'termDate', align: 'center' },
				{ title: '员工类型', ellipsis: true, sorter: false, dataIndex: 'empType', align: 'center' },
				{ title: '出勤天数', ellipsis: true, sorter: false, dataIndex: 'attDays', align: 'center' },
				{ title: '休息天数', ellipsis: true, sorter: false, dataIndex: 'restDays', align: 'center' },
				{ title: '应出勤/时', ellipsis: true, sorter: false, dataIndex: 'standardLobor', align: 'center' },
				{ title: '实际出勤/时', ellipsis: true, sorter: false, dataIndex: 'factLabor', align: 'center' },
				{ title: '缺勤时数', sorter: false, dataIndex: 'absenceDays', ellipsis: true, align: 'center' },
				{ title: '公出/时', dataIndex: 'gcHours', sorter: false, align: 'center' },
				{ title: '周末应出勤/时', sorter: false, dataIndex: 'weekStandardLobor', width: 150, align: 'center' },
				{ title: '周末实际出勤/时', sorter: false, dataIndex: 'weekFactLabor', width: 150, align: 'center' },
				{ title: '周末有薪假/时', width: 150, sorter: false, dataIndex: 'weekendPaidLeave', align: 'center' },
				{ title: '周末事假/时', width: 150, sorter: false, dataIndex: 'weekSjHours', align: 'center' },
				{ title: '周末病假/时', width: 150, sorter: false, dataIndex: 'weekBjHours', align: 'center' },
				{ title: '工作日加班/时', width: 150, sorter: false, dataIndex: 'oT1', align: 'center' },
				{ title: '周末加班/时', dataIndex: 'oT2', sorter: false, align: 'center' },
				{ title: '法定加班/时', dataIndex: 'oT3', sorter: false, align: 'center' },
				{ title: '加班合计/时', dataIndex: 'ot', sorter: false, align: 'center' },
				{ title: '调休/时', dataIndex: 'txHours', sorter: false, align: 'center' },
				{ title: '年假/时', dataIndex: 'njHours', sorter: false, align: 'center' },
				{ title: '婚假/次', dataIndex: 'hjHours', sorter: false, align: 'center' },
				{ title: '产假/时', dataIndex: 'cjHours', sorter: false, align: 'center' },
				{ title: '陪产假/时', dataIndex: 'pcHours', sorter: false, align: 'center' },
				{ title: '工伤假/时', dataIndex: 'gsHours', sorter: false, align: 'center' },
				{ title: '哺乳假/时', dataIndex: 'brHours', sorter: false, align: 'center' },
				{ title: '丧假/时', dataIndex: 'ssHours', sorter: false, align: 'center' },
				{ title: '产检假/时', sorter: false, dataIndex: 'cjjHours', align: 'center' },
				{ title: '有薪假合计/时', dataIndex: 'paidLeaveHours', sorter: false, align: 'center' },
				{ title: '事假/时', dataIndex: 'sjHours', sorter: false, align: 'center' },
				{ title: '病假/时', dataIndex: 'bjHours', sorter: false, align: 'center' },
				{ title: '夜班/次', ellipsis: true, sorter: false, dataIndex: 'nightNums', align: 'center' },
				{ title: '迟到/次', ellipsis: true, sorter: false, dataIndex: 'lateNums', align: 'center' },
				{ title: '早退/次', ellipsis: true, sorter: false, dataIndex: 'earlyNums', align: 'center' },
				{ title: '旷工/次', sorter: false, dataIndex: 'absentNums', align: 'center' },
				{ title: '迟到/分钟', sorter: false, dataIndex: 'lateMinutes', align: 'center' },
				{ title: '早退/分钟', sorter: false, dataIndex: 'earlyMinutes', align: 'center' },
				{ title: '旷工/时', sorter: false, dataIndex: 'absentHours', align: 'center' },
				{ title: '因私签卡/次', dataIndex: 'signCardNums', sorter: false, align: 'center' },
			],
			tableData: [],

			empTypeList: [],
			empType: undefined,
			initialValue: undefined,
		};
	},
	created() {
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
		this.columns = this.getColumn('考勤周汇总', this.columns, 150);
	},
	async mounted() {
		this.initCompany();
		this.attendanceGroup = await this.initAttGroup();
		this.empTypeList = await Dic.getInstance().getDicAsync('EmpType');
		this.initialValueInit();
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this.selectedRowObj = {};
			this._empattmonthlyServiceProxy
				.getEmpAttWeekList(
					moment(this.startTime),
					moment(this.endTime),
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.empType,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;

					this.totalItems = res.totalCount;
				});
		},
		/**
		 *初始化时间区间
		 */
		initialValueInit() {
			let now = new moment();
			let day = now.day() || 7;
			let oneDayTime = 24 * 60 * 60 * 1000;
			this.startTime = now - (day - 1) * oneDayTime;
			this.endTime = now + (7 - day) * oneDayTime;
			this.initialValue = [moment(this.startTime), moment(this.endTime)];
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		//选择公司
		handleCompanyChange(value) {
			this.comPanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		customRow: (record) => {
			return {
				on: {
					click: () => {
						record.checked = !record.checked;
						if (record.checked) {
							//判断是否存在
							let u = _this.selectRows.find((item) => item.id == record.id);
							if (!u) {
								_this.selectedRowKeys.push(record.id);
								_this.selectRows.push(record);
							}
						} else {
							_this.selectedRowKeys = [..._this.selectedRowKeys.filter((item) => item != record.id)];
							_this.selectRows = [..._this.selectRows.filter((item) => item.id != record.id)];
						}
					},
				},
			};
		},
		tableSelectAll(record, rows, crows) {
			if (record) {
				//更改表格数据勾选状态
				this.tableData.map((item) => {
					item.checked = true;
				});
				this.tableData.forEach((item, index) => {
					//判断是否存在
					let u = this.selectRows.find((t) => t.id == item.id);
					if (!u) {
						this.selectRows = [...this.selectRows, item];
						this.selectedRowKeys = [...this.selectedRowKeys, item.id];
					}
				});
			} else {
				//更改表格数据勾选状态
				this.tableData.forEach((item, index) => {
					item.checked = false;
					this.selectRows = this.selectRows.filter((t) => t.id != item.id);
					this.selectedRowKeys = _.difference(this.selectedRowKeys, [item.id]);
				});
			}
		},
		// 选择表格
		onSelectChange(record, rows) {
			record.checked = !record.checked;
			if (record.checked) {
				this.selectedRowKeys.push(record.id);
				this.selectRows.push(record);
			} else {
				this.selectRows = [...this.selectRows.filter((item) => item.id != record.id)];
				this.selectedRowKeys = [...this.selectedRowKeys.filter((item) => item != record.id)];
			}
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		//初始化考勤组
		async initAttGroup() {
			return new Promise((resolve) => {
				this.groupListApi.getGroupListByManage().then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.search();
		},
		//时间段
		onChange(date, dateString) {
			// console.log(date, dateString);
			this.startTime = dateString[0];
			this.endTime = dateString[1];
			this.search();
		},
		/**
		 * 分析
		 */
		analysis() {
			modalHelper
				.create(
					WeekAnalysis,
					{
						attDate: this.initialValue,
					},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					this.getData();
				});
		},
		/**
		 * 导出
		 */
		exporter() {
			if (this.tableData.length <= 0) {
				return abp.message.warn('暂无数据');
			}
			this.loading = true;
			this._empattmonthlyServiceProxy
				.getEmpAttWeekListToExcel(
					moment(this.startTime),
					moment(this.endTime),
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.empType,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
